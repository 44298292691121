.back-to-top {
    position: fixed;
    bottom: 20px; 
    right: 20px; 
    background-color: #436850; 
    color: #fff; 
    border: none;
    border-radius: 50%;
    width: 40px; 
    height: 40px; 
    font-size: 20px; 
    line-height: 40px; 
    text-align: center;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .back-to-top.visible {
    opacity: 1;
    visibility: visible;
  }
  
  // .back-to-top:hover {
  //   background-color: #0056b3; /* Button background color on hover */
  // }
  
  .back-to-top::before {
    content: '\25b2'; 
    position: absolute;
    top: 50%;
    right: 2%;
    transform: translateY(-50%);
    display: block;
    width: 100%;
    text-align: center;
  }
  