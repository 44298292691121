$tablet-breakpoint: 768px;
$desktop-breakpoint: 1279px;

 



@mixin tablet {
    @media screen and (min-width: $tablet-breakpoint) {
        @content;
    }
}

@mixin desktop {
    @media screen and (min-width: $desktop-breakpoint) {
        @content;
    }
}

@mixin h2-heading {
        padding: 3% 0 3% 0;
        text-align: center;
 
         @include tablet {
             text-align: left;
             font-size: 1.75rem;
             font-weight: 700;
         }

         @include desktop {
            text-align: center;
         }

         

}

@mixin images {
    width: 100%;
    height: fit-content;
    object-fit:cover;

    @include tablet {
      height: fit-content;
      width: 50%;
    }

    @include desktop {
      height: 100vh;
    }

  
  }