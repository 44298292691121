@use "../../styles/mixins" as *;

.form {
  padding: 1rem;
  height: 100%;
  width: 100%;
  background-color: #fff;

  @include desktop {
    padding: 1rem 2rem;
  }

  &__container {
    padding: 2rem 0;

    @include tablet {
      padding: 2rem 3rem;
    }
  }

  &__heading {
    padding: .5rem 0;
    @include desktop {
      font-size: 2rem;
    }
  }

  &__copy {
    @include tablet {
      width: 50%;
    }
  }
}

.contact {
  display: flex;
  flex-direction: column;
  align-items: left;

  &__heading {
    padding: 1rem;

   
  }

  &__input {
    width: 100%;
    @include tablet {
      width: 50%;

    }
  }

  &__phone {
    text-decoration: none;
    color: #fff;
  }

  &__buttons {
    width: 100%;
  }

  &__button {
    width: 50%;
    background-color: #ff5733;
    border: none;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
    padding: 10px 20px;
    border-radius: 6px;
    margin: 0.5rem 0;
  }

  &__success {
    text-align: center;
    padding: 5%;
  }
}

form {
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: left;

  @include tablet {
    padding : 0 3rem;
  }

}

input,
textarea {
  padding: 8px;
  margin-bottom: 16px;
  width: 100%;
}

button {
  padding: 10px 20px;
  background-color: #436850;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 6px;
}

button:hover {
  background-color: #45a049;
}


