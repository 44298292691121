@use "./styles/fonts" as *;
@use "./styles/mixins" as *;


* {
  padding: 0;
  margin: 0;
  font-family: "inter";
  box-sizing: border-box;
}



