@use "../../styles/mixins" as *;


.equipment {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-bottom: 1rem;

    @include tablet {
        height: fit-content;
    }

&__heading {
     @include h2-heading;
     width: 100%;

     @include tablet {
        text-align: center;
     }
}

    &__container,
    &__hidden {
        display: flex;
        gap: 1rem;
        flex-direction: column;
        padding: 2% 5%;

        @include tablet {
            flex-direction: row;
            flex-wrap: wrap;
        }

        @include desktop {
            flex-wrap:nowrap ;
            justify-content: center;
        }
    }

    &__card {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1rem;
        box-shadow: 0px 10px 15px 1px rgba(0,0,0,0.1);
        border-radius: 6px;
        padding: 1rem;
        background-color: #fff;
        transition: transform .5s;

        &::after {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          transition: opacity 2s cubic-bezier(.165, .84, .44, 1);
          box-shadow: 0 8px 17px 0 rgba(0, 0, 0, .2), 0 6px 20px 0 rgba(0, 0, 0, .15);
          content: '';
          opacity: 0;
          z-index: -1;
        }
      
        &:hover,
        &:focus {
          transform: scale3d(1.006, 1.006, 1);
      
          &::after {
            opacity: 1;
          }
        }
      
      

        @include tablet {
            height: 32rem;
            width: calc(50% - 1rem);
        }

        @include desktop {
            width: calc(25% - 1rem);
            height: 40rem;
        }


        
      
    }

    &__hidden {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }

    &__image {
        height: 18rem;
        width: auto;

        
        

        @include tablet {
            height: 60%;
            width: auto;
        }

        @include desktop {
            width: auto;
            height: 60%; 
        }
    }

    &__description {
        height: fit-content;

       
    }

    &__details {
        padding: 3% 0;
      
    }

    &__more {
        font-weight: 700;
    }

   
}