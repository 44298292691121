@use "../../styles/mixins" as *;

.footer {
  height: fit-content;
  width: 100%;
  background-color: #436850;
  padding: 1rem 5%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1rem;
    height: 100%;
  }

  &__socials {
    &--heading {
      color: #fff;
      padding: 1rem 0;
    }

    &--container {
      display: flex;
      flex-direction: row;
      gap: 1rem;
    }
  }

  &__insta {
    background-image: url("../../assets/icons/instagram.png");
    background-size: contain;
    background-repeat: no-repeat;
    width: 3rem;
    height: 3rem;
    cursor: pointer;
  }

  &__fb {
    background-image: url("../../assets/icons/facebook.png");
    background-size: contain;
    background-repeat: no-repeat;
    width: 3rem;
    height: 3rem;
    cursor: pointer;
  }

  &__contact {
    display: flex;
    flex-direction: column;

    &--heading {
      color: #fff;
      padding: 1rem 0;
    }

    &--phone {
      color: #fff;
      text-decoration: none;
    }
  }

  &__button {
    background-color: #ff5733;
    border: none;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    padding: 10px 20px;
    border-radius: 6px;
    width: 70%;
    margin: 0.5rem 0;

    @include tablet {
      width: 20%;
    }
  }

  &__copy {
    color: #fff;
  }


}
