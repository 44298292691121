@use "../../styles/mixins" as *;

.main {
  display: flex;
  flex-direction: column;
  // gap: 1rem;
  position: relative;
  height: 100vh;

//   @include tablet {
//     flex-direction: row;
//   }

//   @include desktop {
//     padding: 0 5%;
//     flex-direction: row;
//     height: 85.5vh;
//     gap: 0;
//     position: relative;
//   }

  &__left {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    // height: 30vh;

    // @include tablet {
    //   width: 50%;
    //   justify-content: center;
    // }

    // @include desktop {
    //   flex-direction: column;
    //   height: 100%;
    // }
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: #fff;
    width: 100%;
    height: 30%;
  }

  &__heading {
    padding: .2rem;
    font-size: 2.3rem;
    font-weight: 700;

    @include tablet {
      font-size: 3.5rem;
    }

    @include desktop {
      font-size: 5rem;
      font-weight: 700;
      color: #fff;
    }
  }

  &__heading--sub {
    font-size: 2.3rem;
    padding: .2rem;

    @include tablet {
      font-size: 2.5rem;
    }

    @include desktop {
      font-size: 3rem;
      font-weight: 700;
      color: #fff;
    }
  }

  &__sub-heading {
    color: #e0e0e0;

    @include tablet{
      font-size: 2rem;
    }

    @include desktop {
      letter-spacing: 0.2rem;
      font-weight: 700;
    }
  }



  &__slide {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 100vh;
    width: 100vw;
    position: relative;
    top: 0;
    left: 0;
    


    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5); // Dark overlay color with 50% opacity
      z-index: 1; // Position the overlay behind the main content
    }

  }
}

.slide1 {
    background-image: url("../../assets/images/gallery1.webp");
}

.slide2 {
    background-image: url("../../assets/images/gallery2.webp");
}

.slide3 {
    background-image: url("../../assets/images/gallery3.webp");
}

.slide4 {
    background-image: url("../../assets/images/gallery4.webp");
}

.slide5 {
    background-image: url("../../assets/images/gallery5.webp");
}

.slide6 {
  background-image: url("../../assets/images/vending01.webp");
}