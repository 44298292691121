@use "../../styles/mixins" as *;

.services {
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: #f6f5f5;
  display: flex;
  flex-direction: column;
  

  @include tablet {
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  &__image {
    @include images;
  }

  &__heading {
    font-weight: 500;
    height: auto;
    line-height: 1.5;
    padding: 5%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include tablet {
      width: 50%;
    }
    @include desktop {
        width: 50%;
    }
  }

  &__heading--h2 {
    padding: 0.5rem 0;
    
    @include tablet {
      font-size: 1.8rem;
    }
  }

  &__heading--h3 {
    padding: 0.5rem 0;

    @include tablet {
      font-size: 1.3rem;
    }
  }

  &__copy {
    @include tablet {
      font-size: 1.1rem;
    }

    @include desktop {
      font-size: 1.2rem;
    }
  }

  &__action {
    background-color: #ff5733;
    color: #fff;
    font-weight: 500;
    border: none;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    padding: 10px 20px;
    border-radius: 6px;
    margin: 1rem 0;
    text-decoration: none;
    text-align: center;
    @include tablet {
      width: 60%;
    }
  }
}
