@use "../../styles/mixins" as *;

.nayax {

    background-color: #e0e0e0;
    
    &__heading {
        @include h2-heading;
        @include tablet {
            text-align: center;
        }
    }

    &__container {
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;

        @include tablet {
            flex-direction: row;

        }
    }


    &__image {
        height: 45vh;
        display: flex;
        object-fit: contain;
    }

    &__copy {
        padding: 1rem 2rem;

        @include tablet {
            width: 50%;
            font-size: 1.3rem;
            font-weight: 500;
        }
    }

    &__link {
        text-decoration: none;
        color: #000;
        font-weight: 700;
    }
    
}