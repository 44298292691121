@use "../../styles/mixins" as *;

.location {
    display: flex;
    gap: 1rem;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: #e0e0e0;
    height: 14rem;
    padding: 0 5%;
    flex-direction: column;

    @include desktop {
    height: 15rem;
    flex-direction: row;
    gap: 2rem;

    }

    &__heading {
        color: #436850;
        font-weight: 400;
        font-size: 1.5rem;
        line-height: 1.3;

        @include desktop {
        font-size: 1.5rem;
        }
    }

    &__buttons {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      @include tablet {
        width: 50%;

      }
      
    }

    &__button {
        background-color: #FF5733;
        color: white;
        border: none;
        font-size: 16px;
        cursor: pointer;
        transition: background-color 0.3s ease;
        padding: 12px 24px;
        border-radius: 6px;
        font-weight: 400;
        
        


        @include desktop {
        }
       
    }

    &__contact--phone {
      text-decoration: none;
      color: #fff;
      font-size: 1.2rem;
      font-weight: 400;
    }
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black overlay */
    z-index: 999; /* Ensure modal appears above other content */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-content {
    background-color: #fff;
    padding: 2rem;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    max-width: 600px;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .close {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    font-size: 1.5rem;
    cursor: pointer;
  }