@use "../../styles/mixins" as *;

.about {
    display: flex;
    flex-direction: column-reverse;

    @include tablet {
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }
  

  &__pic {
    @include images;
  }

  &__heading {
    font-weight: 500;
    height: auto;
    line-height: 1.2;
    padding: 5%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include tablet {
      width: 50%;
    }
    @include desktop {
        width: 50%;
    }

    &--h2 {
      padding: 1rem 0;
      text-align: left;

      @include tablet {
        font-size: 1.75rem;
      }

    }
  }

  &__copy {
    @include tablet {
      font-size: 1.2rem;
    }

    @include desktop {
      font-size: 1.3rem;
    }
  }
}


