@use "../../styles/mixins" as *;

.sale {
    height: 100%;
    padding: 1rem 5% 1rem 5%;
    display: flex;
    flex-direction: column;
    background-color: #e0e0e0;



    @include tablet {
        flex-direction: row;
        justify-content: center;
    }

    &__locations {
        padding-bottom: 1rem;
        display: flex;
        justify-content: center;
        flex-direction: column;

        @include tablet {
            padding-right: 1rem;
            display: flex;
            flex-direction: column;
            width: 40%;
        }
    }

    &__heading {
        padding-bottom: 1rem;
        text-align: center;

        @include tablet {
            text-align: left;
        }
    }

    &__cards {
        display: flex;
        flex-direction: column;

        @include tablet {
            flex-direction: row;
            width: 60%;
        }
    }

  

    &__card {
        margin-bottom: 1rem;
        border-radius: 15px;
        height: 30rem;
        width: auto;
        display: flex;
        flex-direction: column;
        box-shadow: 10px 10px 15px 1px rgba(0,0,0,0.1);
        background-color: #fff;
        overflow: hidden;


        @include tablet {
            flex-direction: row;
            padding: 1rem;
            height: auto;
        }
    }

    &__image {
        border-radius: 6px 6px 0 0;
        overflow: hidden;   
        object-fit: cover;
        
         
    
        @include tablet {
            width: 50%;
            height: auto;
            border-radius: 6px;

        }
    }

    &__copy {
        padding: .5rem;
        display: flex;
        flex-direction: column;
        justify-content:space-evenly;
        height: 60%;
        

        @include tablet {
            width: 40%;
            padding: 0 1rem 1rem 1rem;
        }

        &--copy {
            font-size: .8rem;
        }

        &--heading {
            font-size: 1rem;
        }

        &--sold {
            color: #fff;
            background-color: #ff0000;
            text-align: center;
            border-radius: 4px;
            padding: .2rem;
            width: 50%;
        }
    }
}

